import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

const player = Vue.observable({ player: {} });
Object.defineProperty(Vue.prototype, '$player', {
  get() { return player.player; },
  set(value) { player.player = value; }
});

const home = Vue.observable({ home: {} });
Object.defineProperty(Vue.prototype, '$home', {
  get() { return home.home; },
  set(value) { home.home = value; }
});

const shows = Vue.observable({ shows: {} });
Object.defineProperty(Vue.prototype, '$shows', {
  get() { return shows.shows; },
  set(value) { shows.shows = value; }
});

const schedule = Vue.observable({ schedule: {} });
Object.defineProperty(Vue.prototype, '$schedule', {
  get() { return schedule.schedule; },
  set(value) { schedule.schedule = value; }
});

const podcasts = Vue.observable({ podcasts: {} });
Object.defineProperty(Vue.prototype, '$podcasts', {
  get() { return podcasts.podcasts; },
  set(value) { podcasts.podcasts = value; }
});

const errors = Vue.observable({ errors: {} });
Object.defineProperty(Vue.prototype, '$errors', {
  get() { return errors.errors; },
  set(value) { errors.errors = value; }
});
	
Vue.set(Vue.prototype, '$player', {
	playerMeta:{
            isPodcast: false,
            showTitle: 'ACCC 666',
            showSlug:  'accc',
			showIcon:  '/img/icon/accc.png',
            showTimeSlot:  '10.00 - 12.30',
            podcastTitle:  'ACCC 1312',
            podcastDesc:   'qui ci finisce un testicolo',
            podcastUpload: 'Lumenica 32 Gebbraio'
        },
	playerUrl:'https://stream.esiliati.org:8003/radiogramma.mp3'
	
});

Vue.set(Vue.prototype, '$home', null );
Vue.set(Vue.prototype, '$shows', null );
Vue.set(Vue.prototype, '$schedule', null );
Vue.set(Vue.prototype, '$podcasts', {} );
Vue.set(Vue.prototype, '$errors', [] );



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
