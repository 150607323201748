<template>
    <div class="playerWrapper">
        <TheAudioPlayer file="" :autoPlay="true"/>
    </div>
</template>
<script>
import TheAudioPlayer from "@/components/TheAudioPlayer.vue"
export default {
  components:{
    TheAudioPlayer
  },
  data() {
    return {
      
    };
  },
};
</script>
<style scoped>
</style>
