<template>
  <div class="mainBox" id="app">
    <header><TheHeader /></header>
    <div class="mainWrapper">
        <router-view :key="$route.path" />
    </div>
    <footer><TheFooter /></footer>
  </div>
</template>
<script>
import TheHeader from '@/components/TheHeader'
import TheFooter from '@/components/TheFooter'
import axios from 'axios'
export default {
  components:{
    TheHeader,
    TheFooter
  },
  data(){
    let that=this
    return{
        errorSetter:function(error){
            that.$errors.push(error)
        },
    }
  },
  mounted(){
    window.addEventListener('error', this.errorSetter)
    
    axios.defaults.headers['Pragma'] = 'no-cache';
    axios.defaults.headers['Cache-Control'] = 'no-cache';
    axios
      .get("/api/stations/radiogramma/")
      .then(response => {
        //console.log(JSON.parse(response.data))
        if(response.data){
          this.$home=response.data
        }
        else{
          this.$router.push({name:'NotFound', params:{error: JSON.stringify(response.data)}})
        }
      })
      .catch(err => {
        this.$router.push({name:'NotFound', params:{error: err.message}})
      })
      axios
        .get("/api/stations/radiogramma/shows/")
        .then(response => {
          if(response.data.length){
            this.$shows=response.data
          }else{
            this.$router.push({name:'NotFound', params:{error: JSON.stringify(response.data)}})
          }
        })
        .catch(err => {
          this.$router.push({name:'NotFound', params:{error: err.message}})
        })
    
    let that=this  
    setInterval(function(){
      axios
        .get("/api/stations/radiogramma/")
        .then(response => {
          if(response.data){
            that.$home=response.data
          }
        })
        .catch(err => {
          console.log('Unable to contact api'+err.message)
        })
    },60*1000)
    
    setInterval(function(){
      axios.defaults.headers['Pragma'] = 'no-cache';
      axios.defaults.headers['Cache-Control'] = 'no-cache';
      axios 
        .get("/api/stations/radiogramma/shows/")
        .then(response => {
          if(response.data.length){
            that.$shows=response.data
          }
        })
        .catch(err => {
          console.log('Unable to contact api'+err.message)
        })          
      if(that.$schedule){
        let adesso = new Date()
        adesso.setUTCHours(0,0,0,0)
        axios
          .get("/api/stations/radiogramma/schedules/?start_at__gte="+escape(adesso.toISOString()))
          .then(response => {
            if(response.data.length){
              let days=[]
              let chunks=[]
              response.data.map((e) => {
                let b= new Date(e.start_at)
                let c=days.indexOf(b.getDate())
                if(c>=0){
                  chunks[c].push(e)
                }
                else{
                  days.push(b.getDate())
                  chunks.push([])
                }
              })
              let sched=[]
              var weekdays = new Array(7);
              weekdays[0] = "Domenica";
              weekdays[1] = "Lunedì";
              weekdays[2] = "Martedì";
              weekdays[3] = "Mercoledì";
              weekdays[4] = "Giovedì";
              weekdays[5] = "Venerdì";
              weekdays[6] = "Sabato";
              for(let i in days){
                  sched.push({
                      "dayId": i,
                      "day": weekdays[new Date(chunks[i][0].start_at).getDay()] + ' ' + new Date(chunks[i][0].start_at).getDate(),
                      "shows":chunks[i],
                  })
              }
              this.$schedule=sched.slice()
            }
          })
          .catch(err => {
            console.log('Unable to contact api'+err.message)
          })
      }
      if(Object.keys(that.$podcasts).length){
        for(let i in Object.keys(that.$podcasts)){
            let slug = Object.keys(that.$podcasts)[i]
            axios
              .get("/api/stations/radiogramma/podcasts/?show__slug="+slug)
              .then(response => {
                if(response.data.length){
                  that.$podcasts[slug]=response.data
                }
              })
              .catch(err => {
                console.log('Unable to contact api'+err.message)
              })          
        } 
      }

    },60*60*1000)
  }
};
</script>
<style>
    @import './assets/styles/style.css';
</style>
