import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
// import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/schedule/:dayid',
    name: 'Schedule',
	props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "schedule" */ '@/views/Schedule.vue')
  },
  {
    path: '/shows',
    name: 'Shows',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "shows" */ '@/views/Shows.vue')
  },
  {
    path: '/show/:slug',
    name: 'Show',
	props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "show" */ '@/views/Show.vue')
  },
  {
    path: '/podcast/:slug',
    name: 'Podcast',
	props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "podcast" */ '@/views/Podcast.vue')
  },
  {
    path: "/error",
    alias: "*",
    name: "NotFound",
	props: true,
    component: () => import(/* webpackChunkName: "notfound" */"@/views/NotFound.vue")
  }
]

const router = new VueRouter({
  routes: routes,
  mode: "history"
})

export default router
