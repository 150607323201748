
<template>

    <div id="audio" class="player-wrapper">
        
        <!-- podcast details view -->
        <div v-if="showDetails && playerMeta.isPodcast" class=player-details>
                    <div class="showTitle">{{playerMeta.showTitle}}</div>
                    <div class="timeSlot">{{playerMeta.podcastUpload}}</div>
                    <div class="podcastTitle">{{playerMeta.podcastTitle}}</div>
                    <div class="showDescription">{{playerMeta.podcastDesc}}</div>
        </div>
        <div v-if="showDetails && playerMeta.isPodcast" class=player-extra-controls>

                    <a v-on:click="stop" href="#" title="Play/Pause">
                        <div> 
                            <TheIcon class="bottomShadowIcons" icon="stop"/>
                        </div>
                    </a>
                    <a :href="theFile" title="Download" target="_blank" rel="noopener noreferrer"> 
                        <div> 
                            <TheIcon class="bottomShadowIcons" icon="floppy"/>
                        </div>
                    </a>
                    <router-link :to="{name: 'Show', params:{ slug: this.playerMeta.showSlug}}">
                        <div>
                            <TheIcon class="bottomShadowIcons" icon="link"/> <!--  da reindirizzzare a pagina trasmissione   -->
                        </div>
                    </router-link>
        </div>

        <!-- live stream details view -->
        <div v-if="showDetails && !playerMeta.isPodcast" class=player-details-live>
            <div>
                <img :src="playerMeta.showIcon" alt="" width="90px">
            </div>
            <div class="showDescription">{{playerMeta.showDesc}}</div>
        </div>


        <div class="player">
            
            <div class="player-controls">
                <div v-if="!enablePlayer" class="player-core">
                    <div v-if="loading" class="showTitle">Sto caricando...</div>
                    <div v-else class="timeSlot">...</div>
                </div>

                <!-- play - pause -->
                <div v-if="enablePlayer" class="player-button">
                    <a v-on:click="playing = !playing" title="Play/Pause" href="#">
                            <TheIcon v-if="!playing" icon="play"/>
                            <TheIcon v-else icon="pause"/>
                    </a>
                 </div>
                
                <!-- podcast core view -->
                <div v-if="enablePlayer & playerMeta.isPodcast" class="player-core">    
                        <div class="player-text">
                            <div class="timeSlot">{{playerMeta.showTitle}}</div>
                            <div class="showTitle">{{playerMeta.podcastTitle}}</div>
                        </div>
                        <div  v-on:click="seek" class="player-progress" title="Time played : Total time"> 
                            <div v-for="lo in playerLoaded" :key="lo.id"  :style="{ width: lo.end + '%' , left: lo.start + '%' }" class="player-loaded"></div>
                            <div :style="{ width: this.percentComplete + '%' }" class="player-seeker"></div>
                        </div>

                        <div   class="player-time"> 
                            <div class="player-time-current">{{ currentTime }}</div>
                            <div class="player-time-total">{{ durationTime }}</div>
                        </div>
                </div>
                <!-- live stream core view -->
                <div v-if="enablePlayer & !playerMeta.isPodcast" class="player-core">  
                    <div class="player-text">
                        <div class="timeSlot">{{playerMeta.showTimeSlot}}</div>
                        <div  class="showTitle">{{playerMeta.showTitle}}</div>
                     </div>
                </div>

               
                
                <div v-if="enablePlayer" class="player-button">
                    <a v-on:click="showDetails = !showDetails" title="Show / Hide" href="#">
                             <TheIcon v-if="!showDetails" icon="up" outline/>
                             <TheIcon v-else icon="down"/>
                    </a>
                
                
                </div>
            </div>
            <audio :loop="innerLoop" ref="audiofile" :src="theFile" preload="auto" style="display: none;"></audio>
        </div>
    </div>

</template>
<script>
import TheIcon from './TheIcon.vue';
export default {
    props: {
        file: {
            type: String,
            default: null
        },
        autoPlay: {
            type: Boolean,
            default: false
        },
        loop: {
            type: Boolean,
            default: false
        }
    },
    components:{TheIcon},
    data: () => ({
        enablePlayer:false,
        showDetails:false,
        loading: false,
        theFile: undefined,
        audio: undefined,
        currentSeconds: 0,
        durationSeconds: 0,
        loadedSecond: 0,
        innerLoop: false,
        loaded: false,
        playing: false,
        previousVolume: 35,
        showVolume: false,
        volume: 100,
        timeoutId: null,
        convertTimeHHMMSS: function(val){
            if(val){
                let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);
                return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
            }else{
                return '00:00'
            }
        },
        getLoadedPercent: function(val){
            return parseFloat(val / this.durationSeconds * 100);
        },
        playerMeta:{},
        playerLoaded: [],
        setNavigatorMeta: function(){
            
            if ("mediaSession" in navigator){
                navigator.mediaSession.metadata = new window.MediaMetadata({
                    title: this.playerMeta.isPodcast ? this.playerMeta.podcastTitle : this.playerMeta.showTitle +' Live!',
                    artist: this.playerMeta.showTitle,
                    //album: "Podcast Name",
                    artwork: [{src: this.playerMeta.showIcon ? this.playerMeta.showIcon : null}]
                });
                if(this.playerMeta.isPodcast && Object.keys(this.$podcasts).length){
                    if(this.$podcasts[this.playerMeta.showSlug]){
                        let that=this
                        let ids=this.$podcasts[this.playerMeta.showSlug].map(pod => pod.id)
                        
                        let prevId=ids.indexOf(this.playerMeta.podcastId)+1<this.$podcasts[this.playerMeta.showSlug].length ? ids[ids.indexOf(this.playerMeta.podcastId)+1] : null
                        let nextId=ids.indexOf(this.playerMeta.podcastId)-1>0 ? ids[ids.indexOf(this.playerMeta.podcastId)-1] : null
                        //console.log(prevId, nextId)
                        if(prevId!=null){
                            navigator.mediaSession.setActionHandler('previoustrack', function(){
                                let prevPod = that.$podcasts[that.playerMeta.showSlug].find(a => a.id == prevId)
                                
                                that.$player = {
                                    playerMeta: {
                                        isPodcast: true,
                                        showTitle: that.playerMeta.showTitle,
                                        showSlug:  that.playerMeta.showSlug,
                                        showTimeSlot:  that.playerMeta.showTimeSlot,
                                        showIcon: that.playerMeta.showIcon,
                                        podcastTitle:  prevPod.title,
                                        podcastDesc:   prevPod.description,
                                        podcastUpload: prevPod.upload,
                                        podcastId: prevId
                                    },
                                    playerUrl: prevPod.url
                                }
                            })
                        }else{
                            navigator.mediaSession.setActionHandler('previoustrack',null)
                        }
                        if(nextId!=null){
                            navigator.mediaSession.setActionHandler('nexttrack', function(){
                                let nextPod = that.$podcasts[that.playerMeta.showSlug].find(a => a.id == nextId)
                                //console.log(nextPod)
                                that.$player = {
                                    playerMeta: {
                                        isPodcast: true,
                                        showTitle: that.playerMeta.showTitle,
                                        showSlug:  that.playerMeta.showSlug,
                                        showTimeSlot:  that.playerMeta.showTimeSlot,
                                        showIcon: that.playerMeta.showIcon,
                                        podcastTitle:  nextPod.title,
                                        podcastDesc:   nextPod.description,
                                        podcastUpload: nextPod.upload,
                                        podcastId: nextId,
                                    },
                                    playerUrl: nextPod.url,
                                }
                            })
                        }else{
                            navigator.mediaSession.setActionHandler('nexttrack', null)
                        }
                    }
                }
                else{
                    navigator.mediaSession.setActionHandler('previoustrack',null)
                    navigator.mediaSession.setActionHandler('nexttrack',null)
                }
            }
        },
    }),
    computed: {
        currentTime() {
            return this.convertTimeHHMMSS(this.currentSeconds);
        },
        durationTime() {
            return this.convertTimeHHMMSS(this.durationSeconds);
        },
        percentComplete() {
            return parseFloat(this.currentSeconds / this.durationSeconds * 100);
        },
        percentLoaded(){
            return parseFloat(this.loadedSecond / this.durationSeconds * 100);
        },
        muted() {
            return this.volume / 100 === 0;
        },
        
    },
    watch: {
        playing(value) {
            
            if (value) { 
                this.setNavigatorMeta();
                return this.audio.play();
            }
            this.audio.pause();
        },
        volume(value) {
            value===value
            this.showVolume = false;
            this.audio.volume = this.volume / 100;
        },
        $player(val) {
            //console.log(this.$player)
            if(this.theFile != val.playerUrl){ //madonnna buttanazza scartavetrata sull'asfalto!
                this.theFile = val.playerUrl;
                this.enablePlayer = false;
                this.loading = true;
                this.audio.load();
                //console.log('new url on player:' ,val);
                this.playing = false;
            }
            if(this.playerMeta != val.playerMeta){
                this.playerMeta = val.playerMeta
                this.setNavigatorMeta()
            }
        },
        showDetails(value){
            let that=this
            if(value){
                setTimeout(function(){that.showDetails=false}, 30*1000)
            }
        },
        
    },
    methods: {
        
        load() {
            if (this.audio.readyState >= 2) {
                this.loaded = true;
                this.loading = false;
                this.enablePlayer = true;
                //console.log('loaded file:', this.playerMeta);
                this.durationSeconds = parseInt(this.audio.duration);
                return this.playing = this.autoPlay;
            }
            // settiamo un flag l'errore a video? si l'ho fatto
            this.$router.push({name:'NotFound', params:{error: 'Audio error'}})
        },
        mute() {
            if (this.muted) {
                return this.volume = this.previousVolume;
            }

            this.previousVolume = this.volume;
            this.volume = 0;
        },
        seek(e) {
            if (!this.playing || e.target.tagName === 'SPAN') {
                return;
            }
            
            const el = this.$el.querySelectorAll('.player-progress')[0].getBoundingClientRect();
            const seekPos = (e.clientX - el.left) / el.width;
            //console.log(this.$el)
            this.audio.currentTime = parseInt(this.audio.duration * seekPos);
        },
        stop() {
            this.playing = false;
            this.audio.currentTime = 0;
        },
        update(e) {
            e==e
            this.currentSeconds = parseInt(this.audio.currentTime);
            try{
                let tempLoaded=[]
                for(let j=0; j<this.audio.buffered.length; j++){
                    tempLoaded.push({start: this.getLoadedPercent(this.audio.buffered.start(j)), end: this.getLoadedPercent(this.audio.buffered.end(j))-this.getLoadedPercent(this.audio.buffered.start(j)), id:j.toString()+'-'+(this.getLoadedPercent(this.audio.buffered.end(j))-this.getLoadedPercent(this.audio.buffered.start(j))).toString() })
                }
                this.playerLoaded = tempLoaded.slice()
            }
            catch(err){
                console.log(err)
            }
            //this.loadedSecond = parseInt(this.audio.buffered.end(this.audio.buffered.length-1));
        }
    },
    created() {
        this.innerLoop = this.loop;
        this.theFile = this.file;
    },
    mounted() {
        this.audio = this.$el.querySelectorAll('audio')[0];
        this.audio.addEventListener('timeupdate', this.update);
        this.audio.addEventListener('loadeddata', this.load);
        this.audio.addEventListener('pause', () => { this.playing = false; });
        this.audio.addEventListener('play', () => { this.playing = true; });
    }
};
</script>

