<template>
     <!-- qui c'era un DIV di troppo-->
    <div  class="livePage" v-if="home">
        <div class="header2">
            <div class="roundButton">
                <a v-on:click="play(home.on_air_now)" href="#">
                    <TheIcon icon="play" :outline="false" :background="false" />
                </a>
            </div>
            
            <div class="headerContent" v-if="home.on_air_now">
                <div class="timeSlot">Adesso -> {{ formatHHMM(home.on_air_now.end_at) }}</div>
                <div class="showTitle">{{ home.on_air_now.title }}</div>
                <div class="showDescription">{{ home.on_air_now.description }}</div>
            </div>
            
            <div v-if="home.on_air_now.show!=null" class="roundButton"> 
                <router-link :to="{name:'Show',params:{slug:home. on_air_now.show.slug}}">
                    
                        <TheIcon icon="link" :outline="false" :background="false" />
                    
                </router-link>
            </div>
        </div>



        <!-- sfondo gallery radiogramma ------------------------------------------------------------>
        <div class="stationPicture" :style="{'background-image': 'url('+home.background+')'}">
            <div class="imageShadowsContainer">
                <div class="topShadow">
                </div>
                <div class="middleSpacer"> &nbsp; <br> &nbsp; </div>
                <div class="bottomShadow">
                    <!--array link della radio-->
                    <div v-for="item in home.external_links" :key="item.id" class="bottomShadowIcons">
                        <a :href="item.url" target="_blank" rel="noopener noreferrer">
                            <!-- <img class="bottomShadowIcons" :src="require(`@/assets/icons/social/buridda.png`)"> -->
                            <TheIcon :icon="item.social" :background="false" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- spieghino radiogramma che la prossima trasmissione sopra il player faceva confusione-->
        <div class="bottomSlot">
            <div class="headerContent">
                <div class="showDescription" v-html="home.description" />
            </div>
            <div v-if="errors.length" class="headerContent">
                <div v-for="err in errors" :key="err.timestamp" class="showDescription">{{err.error.fileName}} || {{err.message}}</div>
            </div>
                
        </div>
    </div>
    <div v-else class="loadingContainer"><TheLoading /></div>
    
</template>

<script>
// @ is an alias to /src
//import store from "@/store"
import TheIcon from '@/components/TheIcon'
import TheLoading from '@/components/TheLoading'
export default {
  name: 'Home',
  components:{
    TheIcon,
    TheLoading,
  },
  data(){
    return{
        formatHHMM: function(val){
          let data=new Date(val+'+0000')
          let h = data.getHours() 
          let m = data.getMinutes() ? data.getMinutes() : '00'
          return h + ':' + m
        },
    }  
  },
  computed:{
    home(){
        return this.$home
    },
    errors(){
        return this.$errors
    }
  },
  methods:{
    play(e){
        this.$player = {
            playerMeta:{
                isPodcast: false,
                showTitle: e.title,
                showDesc: e.description,
                showIcon: e.show ? e.show.icon : null ,
                showSlug:  e.show ? e.show.slug : null,
                showTimeSlot:  'Adesso -> '+this.formatHHMM(e.end_at),
                podcastTitle:  '',
                podcastDesc:   '',
                podcastUpload: ''
            },
            playerUrl: this.home.stream_url,
            file:''
        }
    }
  },
}
</script>
