<template>
     <!-- qui c'era un DIV di troppo-->
        <div class="loading">
            <div class="loader"></div>
            <div class="loaderText">CARICAMENTO</div>
        </div>
</template>

<script>
// @ is an alias to /src
export default {
name: 'TheLoading',
}
</script>