<template>
    <div class="header1">
        <div v-if="!homeLeft" class="roundButton">
            <router-link :to="{name:'Schedule', params:{dayid: '0'} }">
                <TheIcon icon="calendar" :outline="false" :background="false"/>
            </router-link>
        </div>
        <div v-else class="roundButton">
            <router-link :to="{name:'Home'}">
                <TheIcon icon="home" :outline="false" :background="false"/>
            </router-link>
        </div>
        <!--<div class="headerContent"><img class="rgLogo" src="@/assets/images/logo.png" alt="logo"></div>-->
        <div class="headerContent"> <TheLogo /> </div>
        <div v-if="homeRight" class="roundButton">
            <router-link :to="{name:'Home'}">
                <TheIcon icon="home" :outline="false" :background="false"/>
            </router-link>
        </div>
        <div v-else class="roundButton">
            <router-link :to="{name:'Shows'}">
                <TheIcon icon="megaphone" :outline="false" :background="false"/>
            </router-link>
        </div>
    </div>
</template>
<script>
import TheIcon from '@/components/TheIcon'
import TheLogo from '@/components/TheLogo'
export default {
  name: 'TheHeader',
  components:{
    TheIcon,
    TheLogo
  },
  computed:{
    homeLeft(){
        if(this.$route.name){
          return this.$route.name=='Schedule' ? 1 : 0
        }else{
          return 0
        }
    },
    homeRight(){
        if(this.$route.name){
          return (this.$route.name.includes('Show') || this.$route.name.includes('Podcast')) ? 1 : 0
        }else{
          return 0
        }
    }
  }
};
</script>

